import { unique, distinct } from "../../utils/unique";
export default {
  state: {
    ids: [], //托运单id
    shipmentNo: [], //托运单号
    deliveryCompany: {},
    loginShipmentNo: {}, //登录时候的托运单号
    sendType: "", //存托运单货运方式
    fileList: [], //存快递图片
    scanStatus: 1, //当前签收状态
    shipmentid: "", //托运单id
    billType: "", //单据类型
    signBy: "", //签收类型
    info: "", //签收备注
    express: "", //存是否需要上传面单
    location: {}, //经纬度
    secondUpload: false, //第二次上传快递单
  },
  mutations: {
    add_ids(state, payload) {
      state.ids.push(payload);
      state.ids.forEach((ele) => {
        if (ele == null || undefined) {
          state.ids.splice(ele, 1);
        }
      });
      state.ids = unique(state.ids);
    },
    add_shipmentNo(state, payload) {
      state.shipmentNo.push(payload);
      state.shipmentNo = distinct(state.shipmentNo, "id");
    },
    save_shipmentNo(state, payload) {
      state.shipmentNo = payload;
    },
    save_loginShipmentNo(state, payload) {
      state.loginShipmentNo = payload;
    },
    //清空id和托运单号
    clear_ids_shiomentNo(state) {
      state.ids = [];
      state.shipmentNo = [];
    },
    save_deliveryCompany(state, payload) {
      state.deliveryCompany = payload;
    },
    save_sendType(state, payload) {
      state.sendType = payload;
    },
    save_fileList(state, payload) {
      state.fileList = payload;
    },
    save_scanStatus(state, payload) {
      state.scanStatus = payload;
    },
    save_shipmentid(state, payload) {
      state.shipmentid = payload;
    },
    save_billType(state, payload) {
      state.billType = payload;
    },
    save_signBy(state, payload) {
      state.signBy = payload;
    },
    save_info(state, payload) {
      state.info = payload;
    },
    save_express(state, payload) {
      state.express = payload;
    },
    save_location(state, payload) {
      state.location = payload;
    },
    save_secondUpload(state, payload) {
      state.secondUpload = payload;
    },
  },
  namespaced: true,
};
