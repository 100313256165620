import Vue from "vue";
import App from "./App.vue";
// 引入 Vconsole
// import Vconsole from "vconsole";
// new Vconsole();
import store from "./store";
import router from "./router";
import { Button, Field, Form, Cell, CellGroup, NavBar, Toast, Dialog, Icon, Tag, Loading, Uploader } from "vant";
Vue.config.productionTip = false;
Vue.config.errorHandler = function (err) {
  // 将错误信息保存在变量中
  const errorMessage = err.message;
  // 抛出错误信息到前端页面
  alert(errorMessage + "err");
  // console.log(errorMessage, "mess");
};
Vue.config.warnHandler = function (warn) {
  alert(warn + "warn");
  // console.log(warn, "warn");
};
Vue.use(Button).use(Field).use(Form).use(Cell).use(CellGroup).use(NavBar).use(Toast).use(Icon).use(Dialog).use(Tag).use(Loading).use(Uploader);
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount("#app");
