import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import user from "./modules/user";
import scan from "./modules/scan";
import loading from "./modules/loading";
Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    user,
    scan,
    loading,
  },
  plugins: [
    createPersistedState({
      storage: localStorage,
      key: "phoneScan",
    }),
  ],
});
export default store;
