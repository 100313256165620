import store from "../store/index";
import axios from "axios";
import { Toast } from "vant";
import wx from "weixin-js-sdk";
// import { userLogout } from "./api";
// import router from "../router/index";
const url = "https://tms.chohogroup.com";//正式环境
// const url = "http://192.168.21.143:8280";
// const url = "http://172.16.23.188:8280";
// const url2 = "http://192.168.21.148:8280";
const request = axios.create({
  baseURL: url,
  timeout: 60000,
  retry: 3, //设置全局重试请求次数
  // retryDelay: 1000, //设置全局请求间隔
});
//请求拦截器
request.interceptors.request.use(
  function (config) {
    let accessToken = store.state.user.accessToken;
    let refreshToken = store.state.user.refreshToken;
    // console.log(accessToken, "accesstoken");
    config.headers["token"] = "bearer " + accessToken;
    config.headers["refresh"] = refreshToken;
    store.state.loading.isLoading = true;
    return config; // config
  },
  function (error) {
    //请求错误时做些事
    // console.log(error, "请求错误");
    Toast(error);
    return Promise.reject(error);
  }
);
//响应拦截器
request.interceptors.response.use(
  async function (res) {
    if (res.headers?.accesstoken) {
      //存返回的token
      store.commit("user/save_accesstoken", res.headers.accesstoken);
      //给headers里面加上请求头
      res.config.headers["token"] = "bearer " + res.headers.accesstoken;
    }
    if (res.headers?.refreshtoken) {
      store.commit("user/save_refreshtoken", res.headers.refreshtoken);
      res.config.headers["refresh"] = res.headers.refreshtoken;
    }
    // if (res.status === 200) {
    store.state.loading.isLoading = false;
    // }
    if (res.data?.retCode != "000000") {
      if (res.data.retInfo) {
        Toast(res.data.retInfo);
      }
      if (res.data?.retCode == "000006") {
        //如果验证码是006跳到登录页
        // let accessToken = store.state.user.accessToken;
        // await userLogout(accessToken);
        store.commit("user/logout");
        // await router.push({
        //   path: "/",
        //   query: {
        //     shipmentId: window.localStorage.getItem("shipmentid"),
        //     shipmentId: store.state.scan.shipmentid,
        //   },
        // });
        wx.miniProgram.navigateTo({
          url:"/pages/index/index?shipmentId="+store.state.scan.shipmentid
        })

      }
    }
    return res;
  },
  function (error) {
    //请求错误时做些事
    // console.log(error, "err");
    Toast(error);
    return Promise.reject(error);
  }
);
export default request;
