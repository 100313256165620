<template>
  <div id="wrap" ref="view">
    <div class="header">
      <!-- <van-button @click="changeNumber">切换手机号</van-button> -->
      <div class="topTitle">
        <p style="display: flex; align-items: center; justify-content: space-between">
          <span>当前登录用户:{{ $store.state.user.userInfo.mobile.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2") }}</span>
          <van-button @click="changeNumber" size="mini" type="info">切换用户</van-button>
        </p>
        <p>单据物流方式:{{ scanSendType }}</p>
      </div>
      <van-button class="submit" type="info" size="small" @click="scanCode" :disabled="btnDisabled">点击扫描</van-button>
      <p style="border-bottom: 1px solid #ccc; margin-bottom: 5px">签收单号：</p>
    </div>

    <div class="mainNumber">
      <van-tag v-for="(item, i) in shipmentNoList" :key="item.id" closeable size="medium" type="primary" ref="vantag" @close="close(i)">
        {{ item.shipmentNo }}
      </van-tag>
    </div>
    <!-- v-if="scanSendType == '快递'"  -->
    <van-uploader v-show="isExpress || needSecondUpload" v-model="fileModel" :before-read="individualbeforeRead" :after-read="afterRead" preview-image :max-size="50 * 1024 * 1024" @oversize="onOversize" @delete="deleteFiles">
      <van-button class="submit" type="warning" size="small">上传快递单</van-button>
    </van-uploader>
    <div class="footer">
      <van-button v-show="!needSecondUpload" class="submit" type="info" size="small" @click="clickToSign">点击签收</van-button>
      <van-button v-show="needSecondUpload" class="submit" type="primary" size="small" @click="clickToSecondSign">确认上传</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { uploadPic, getSignConsignDetail } from "../utils/api";
//, getToken, getTicket
import wx from "weixin-js-sdk";
// import { v4 } from "uuid";
// import sha1 from "js-sha1";
export default {
  name: "ScanView",
  components: {},
  data() {
    return {
      BarScanVisible: false,
      fileModel: this.$store.state.scan.fileList, //上传文件
      fileList: this.$store.state.scan.fileList,
      sendType: this.$store.state.scan.sendType,
      express: this.$store.state.scan.express,
      alreadySignOrder: "", //已经签收的托运单
    };
  },
  computed: {
    needSecondUpload() {
      return this.$store.state.scan.secondUpload;
    },
    shipmentId() {
      return this.$store.state.scan.shipmentid;
    },
    shipmentNoList() {
      return this.$store.state.scan.shipmentNo.filter((item) => item.id != this.alreadySignOrder);
    },
    btnDisabled() {
      let signBy = this.$store.state.scan.signBy;
      let express = this.$store.state.scan.express;
      if (signBy == 2 || express) {
        if (this.shipmentNoList.length) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    scanSendType() {
      let tt = this.$store.state.scan.sendType;
      let result;
      if (tt == 1) {
        result = "货运";
      } else if (tt == 2) {
        result = "厂内自提";
      } else if (tt == 3) {
        result = "快递";
      } else if (tt == 4) {
        result = "外贸";
      } else if (tt == 5) {
        result = "快运";
      } else {
        result = "";
      }
      // console.log(result, "rr");
      return result;
    },
    isExpress() {
      return this.$store.state.scan.express;
    },
  },
  watch: {
    shipmentNoList: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.shipmentNoList.length) {
          this.$store.commit("scan/save_sendType", "");
          this.$store.commit("scan/save_express", "");
          this.$store.commit("scan/save_shipmentid", "");
          this.$store.commit("scan/save_scanStatus", 0);
          this.$store.commit("scan/save_signBy", "");
          this.$store.commit("scan/save_secondUpload", false);
          this.fileList = [];
          this.fileModel = [];
          this.$store.commit("scan/save_fileList", this.fileList);
        }
      },
    },
  },
  async created() {
    // // 获取托运单详情
    if (this.$route.query?.shipmentId) {
      let info = JSON.parse(this.$route.query.info);
      // 将小程序里面传过来的值存上
      this.$store.commit("user/save_userInfo", info.additionalInformation.userModel);
      this.$store.commit("user/save_accesstoken", info?.accessToken);
      this.$store.commit("user/save_refreshtoken", info?.refreshToken);
      this.$store.commit("scan/save_location", JSON.parse(this.$route.query.location));
      let id = this.$route.query?.shipmentId;
      this.$store.commit("scan/save_shipmentid", id);
      this.getDetail(id);
    }
  },
  methods: {
    //删除标签
    close(index) {
      this.shipmentNoList.splice(index, 1);
      this.$store.commit("scan/save_shipmentNo", this.shipmentNoList);
      if (!this.shipmentNoList.length) {
        this.$router.push({ path: "/scan", query: {} });
      }
    },
    //点击扫描
    scanCode() {
      this.$router.push({
        path: "/qrcode",
      });
    },
    //点击签收
    clickToSign() {
      let type = this.$store.state.scan.signBy;
      let billType = this.$store.state.scan.billType;
      console.log(billType, "billtype");
      // 判断签收数组长度
      if (this.shipmentNoList.length) {
        // 判断物流方式this.sendType == 3
        // if (this.isExpress) {
        //   if (this.fileList.length) {
        //     if (type == 1 || billType == 3) {
        //       this.$router.push("/consignment");
        //     } else {
        //       this.$router.push("/shipment");
        //     }
        //   } else {
        //     Toast("请上传快递单");
        //   }
        // } else {
        if (type == 1 || billType == 3) {
          this.$router.push("/consignment");
        } else {
          this.$router.push("/shipment");
        }
        // }
      } else {
        Toast("请扫描托运单");
      }
    },
    clickToSecondSign() {
      // console.log(this.fileList, "filelist");
      // console.log(this.$store.state.scan.fileList, "storeFilist");
      let type = this.$store.state.scan.signBy;
      let billType = this.$store.state.scan.billType;
      // 判断签收数组长度
      if (this.shipmentNoList.length) {
        // 判断物流方式this.sendType == 3
        if (this.needSecondUpload) {
          if (this.fileList.length) {
            if (type == 1 || billType == 3) {
              this.$router.push("/consignment");
            } else {
              this.$router.push("/shipment");
            }
          } else {
            Toast("请上传快递单");
          }
        } else {
          if (type == 1 || billType == 3) {
            this.$router.push("/consignment");
          } else {
            this.$router.push("/shipment");
          }
        }
      } else {
        Toast("请扫描托运单");
      }
    },
    //上传附件
    individualbeforeRead(file) {
      if (file.length) {
        file.forEach((item) => {
          if (item.type !== "image/jpeg" && item.type !== "image/png" && item.type !== "image/jpg") {
            Toast("请上传 jpg,png,jpeg 格式图片");
            return false;
          }
        });
      } else {
        if (file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/jpg") {
          Toast("请上传 jpg,png,jpeg 格式图片");
          return false;
        }
      }
      return true;
    },
    async afterRead(file) {
      let formData = new FormData();
      if (file.length) {
        file.forEach((item) => {
          formData.append("files", item.file);
        });
      } else {
        formData.append("files", file.file);
      }
      let res = await uploadPic(formData);
      // console.log(res, "rr");
      if (res.data.retCode == "000000") {
        let arr2 = res.data.data.map((item) => {
          return {
            url: item,
            isImage: true,
          };
        });
        this.fileList = this.fileList.concat(arr2);
        // console.log(this.fileModel, "fileModel");
        this.fileModel = this.fileList;
        this.$store.commit("scan/save_fileList", this.fileList);
        Toast("上传成功");
      } else {
        Toast("上传失败");
        this.fileList = [];
        this.$store.commit("scan/save_fileList", this.fileList);
        this.fileModel = [];
      }
    },
    onOversize() {
      Toast("图片大小不能超过50mb");
    },
    deleteFiles() {
      this.fileList = this.fileModel;
      this.$store.commit("scan/save_fileList", this.fileList);
      // console.log(this.fileList, "filelist");
    },
    // 获取传递过来的托运单详情
    async getDetail(id) {
      const { roleId } = this.$store.state.user.userInfo;
      let res = await getSignConsignDetail(id);
      if (res.data.retCode == "000000") {
        let saveEverything = false;
        let detail = res.data.data.shipmentModel;
        let signModels = res.data.data?.signModels;
        if (res.data.data.shipmentModel.complete) {
          Toast("该托运单已签收");
          this.alreadySignOrder = detail.id;
        } else if (!res.data.data?.signModels) {
          this.$store.commit("scan/save_signBy", 1);
          this.$store.commit("scan/save_secondUpload", false);
        }
        // 5.14新添加
        else if (!detail.complete && detail.express && signModels?.length && signModels.find((i) => i.signBy == 1 && !i.back)) {
          this.$store.commit("scan/save_signBy", 1);
          this.$store.commit("scan/save_secondUpload", true);
        } else if (!detail.complete && signModels?.length && signModels.find((i) => i.signBy == 1 && i.back)) {
          this.$store.commit("scan/save_signBy", 1);
          this.$store.commit("scan/save_secondUpload", false);
        } else if (!detail.complete && signModels?.length && signModels.find((i) => i.signBy == 2 && i.back)) {
          this.$store.commit("scan/save_signBy", 2);
          this.$store.commit("scan/save_secondUpload", false);
        } else if (!detail.complete && signModels?.length == 1 && signModels.find((i) => i.signBy == 1 && !i.back)) {
          this.$store.commit("scan/save_signBy", 2);
          this.$store.commit("scan/save_secondUpload", false);
        }
        // -------------------

        const driverCheck = roleId == 2 && this.$store.state.scan.signBy == 1;
        const contactCheck = roleId == 3 && this.$store.state.scan.signBy == 2;
        const contactWxCheck = roleId == 12 && this.$store.state.scan.signBy == 2;
        if (detail.billType != 3) {
          if (driverCheck || contactCheck || contactWxCheck) {
            saveEverything = true;
          } else {
            console.log("非派车单");
            Toast("角色/签收人类型不同");
            saveEverything = false;
          }
        } else {
          if (this.$store.state.scan.signBy == 1) {
            if (driverCheck) {
              saveEverything = true;
            } else {
              Toast("角色/签收人类型不同");
              saveEverything = false;
            }
          } else {
            saveEverything = true;
          }
        }
        // 验证司机手机号
        if (driverCheck && detail?.driverMobile) {
          let windowMobile = this.$store.state.user.userInfo.mobile;
          if (windowMobile != detail.driverMobile) {
            Toast("司机手机号不一致");
            saveEverything = false;
          }
        }
        if (saveEverything) {
          // 存托运单号
          this.$store.commit("scan/save_shipmentNo", []);
          this.$store.commit("scan/add_shipmentNo", {
            id: detail.id,
            shipmentNo: detail.shipmentNo,
          });
          // 存单据类型
          this.$store.commit("scan/save_billType", detail.billType);
          // 存货运方式
          this.$store.commit("scan/save_sendType", detail.sendType);
          // 存扫描状态
          this.$store.commit("scan/save_scanStatus", 1);
          // 存是否需要上传面单
          this.$store.commit("scan/save_express", detail.express);
        } else {
          // 删数据
          this.$store.commit("scan/save_shipmentid", "");
          this.$store.commit("scan/save_shipmentNo", []);
          this.$store.commit("scan/save_billType", "");
          this.$store.commit("scan/save_sendType", "");
          this.$store.commit("scan/save_scanStatus", 0);
          this.$store.commit("scan/save_express", "");
          this.$router.push({ path: "/scan", query: {} });
        }
      }
    },
    // 切换手机号
    changeNumber() {
      wx.miniProgram.navigateTo({
        url: "/pages/index/index?shipmentId=" + this.$store.state.scan.shipmentid,
      });
      this.$store.commit("scan/save_shipmentNo", []);
    },
  },
};
</script>

<style lang="scss" scoped>
#wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background: #efefef;
  // margin: 0 auto;
  .mainNumber {
    flex: 1;
    height: 100%;
    // overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    font-size: 20px;
    align-content: flex-start;
    background: #efefef;
    .van-tag {
      width: 45%;
      // line-height: 20px;
      margin-bottom: 5px;
      font-size: 18px;
      height: 28px;
      justify-content: space-between;
    }
  }
}
.header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 20;
  background: #efefef;
}
.footer {
  position: sticky;
  bottom: 0;
  left: 0;
}
.topTitle {
  display: flex;
  flex-direction: column;
  background: white;
  padding: 5px;
}
.submit {
  width: 100%;
  margin: 5px 0;
  box-sizing: border-box;
}
// 上传附件按钮样式
:deep(.van-uploader__input-wrapper) {
  width: 100%;
  position: sticky;
  left: 0;
  bottom: 8%;
  background: #efefef;
  height: 42px;
}

:deep(.van-uploader__wrapper) {
  justify-content: space-around;
}
:deep(.van-uploader__preview) {
  margin: none;
  width: 45%;
}
:deep(.van-uploader__preview-image) {
  width: 100%;
  height: 170px;
}
:deep(.van-uploader__preview-delete) {
  width: 40px;
  height: 40px;
  border-radius: 0 0 0 20px;
}
:deep(.van-uploader__preview-delete-icon) {
  font-size: 40px;
}
</style>
