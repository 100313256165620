<template>
  <div class="loading">
    <van-loading size="36px">加载中,请稍等...</van-loading>
  </div>
</template>

<script>
export default {
  name: "LoadingView",
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
};
</script>

<style scoped>
.loading {
  /* width: 100%;
  height: 100%; */
  width: 100%;
  text-align: center;
  left: 50%;
  bottom: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  /* padding-top: 5.333333rem; */
}
</style>
