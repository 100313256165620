export function unique(arr) {
  let newArr = []; //建立新数组
  for (let i = 0; i < arr.length; i++) {
    //for循环遍历
    // lastIndexOf()返回-1说明，该值不在数组中
    if (newArr.lastIndexOf(arr[i]) === -1) {
      newArr.push(arr[i]); //将该值加入新数组
    }
  }
  return newArr; //返回新数组
}
export function distinct(arr, key) {
  let newArr = [];
  for (let i = 0; i < arr.length; i++) {
    let flag = true;
    for (let j = 0; j < newArr.length; j++) {
      if (arr[i][key] == newArr[j][key]) {
        flag = false;
        break;
      }
    }
    if (flag == true) {
      newArr.push(arr[i]);
    }
  }
  return newArr;
  // console.log(newArr)
}
