export default {
  namespaced: true,
  state: {
    isLoading: false, //loading全局开关
  },
  mutations: {
    changeisLoading(state, data) {
      state.isLoading = data;
    },
  },
};
