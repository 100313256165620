export default {
  state: {
    userInfo: {},
    accessToken: "",
    refreshToken: "",
    mobile: "", //用户手机号
    sms: "", //用户验证码
  },
  mutations: {
    save_userInfo(state, payload) {
      state.userInfo = payload;
    },
    save_accesstoken(state, payload) {
      state.accessToken = payload;
    },
    save_refreshtoken(state, payload) {
      state.refreshToken = payload;
    },
    save_mobile(state, payload) {
      state.mobile = payload;
    },
    save_sms(state, payload) {
      state.sms = payload;
    },
    logout(state) {
      state.accesstoken = "";
      state.refreshToken = "";
      state.userInfo = {};
    },
  },
  namespaced: true,
};
