import axios from "axios";
import request from "./request";
// 接收手机验证码
export const getPhoneCode = (contactMobile) => request.post("/customer/common/sendMessageCode", { contactMobile });
//根据手机号判断司机是否注册
export const registOrNot = (params) => request.get("/customer/user/existUserByMobile", { params });
//点击提交登录
export const login = (data) => request.post("/security/user/login", data);
// 新用户注册
export const newUserRegister = (data) => request.post("/customer/user/driverRegister", data);
//退出登录
export const userLogout = (token) => request.post("/security/user/logout", { token });
//批量获取托运单详情
export const getBatchDetails = (ids) => request.post("/customer/shipment/getBatchList", { ids });
//单独获取托运单详情
export const getSingleDetail = (shipmentId) => request.get("/manage/shipment/getInfo", { params: { shipmentId } });
//签收获取托运单详情
export const getSignConsignDetail = (shipmentId) => request.get("/customer/shipment/getInfoAndSign", { params: { shipmentId } });
//签收托运单
export const signOrder = (data) => request.post("/customer/sign/signParcels", data);
//根据托运单id获取发货单详情
export const getBatchShipmentDetails = (shipmentId) =>
  request.get("/customer/parcels/getInfoByShipmentId", {
    params: { shipmentId },
  });
//上传图片到服务器
export const uploadPic = (data) => request.post("/customer/common/uploadFile", data);
// 托运单上传面单
export const uploadExpressPic = (data) => request.post("/customer/sign/uploadExpressFile", data);

// ————————————————————微信小程序相关接口
// 获取access——token
export const getToken = (params) => axios.get("/api/cgi-bin/token", { params });
// 获取jsapi-ticket
export const getTicket = (params) => axios.get("/api/cgi-bin/ticket/getticket", { params });
