<template>
  <div id="app">
    <router-view :key="$route.fullPath"></router-view>
    <LoadingView v-show="isLoading"></LoadingView>
  </div>
</template>
<script>
// import { mapState } from "vuex";
import store from "./store/index";
import LoadingView from "./components/LoadingView.vue";
export default {
  name: "App",
  data() {
    return {
      // isLoading:false
    };
  },
  components: {
    LoadingView,
  },
  mounted() {
    // console.log(this.$route, "rute");
    // console.log(mapState(["isLoading"]), "loading");
  },
  computed: {
    isLoading() {
      return store.state.loading.isLoading;
    },
    // console.log(...mapState(["isLoading"]))
  },
};
</script>

<style>
* {
  padding: 0;
  margin: 0;
}
html,
body {
  height: 100vh;
  font-size: 16px;
}
#app {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #efefef;
  padding: 5px;
  overflow: auto;
  overflow-y: auto;
  box-sizing: border-box;
}
/* .van-button--primary {
  background-color: #409eff;
  border: 1px solid #409eff;
  font-size: 16px;
} */
.van-button--small {
  font-size: 18px;
  height: 40px;
}
</style>
