<template>
  <!-- @submit="submit" -->
  <div class="main">
    <van-button @click="ceshi">点击登录</van-button>
    <van-form ref="vanForm">
      <p>
        <img src="../../public/images/logo_white.png" alt="" />
      </p>
      <p class="logis">征和工业物流管理系统</p>
      <p>
        <van-field
          :readonly="filedDisabled"
          v-model.trim="userData.username"
          type="digit"
          label="手机号"
          name="tel"
          :rules="[
            { required: true, message: '请填写手机号' },
            { pattern: /^1[0-9][0-9]{9}$/, message: '手机号格式错误' },
          ]" />
      </p>
      <p>
        <van-field v-model.trim="userData.mobileCode" center clearable label="短信验证码" name="sms" :rules="[{ required: true, message: '请输入验证码' }]">
          <template #button>
            <van-button native-type="button" @click="sendSms" size="small" type="primary" :disabled="btnDisabled">{{ btnDisabled ? `${count}秒后重试` : `发送验证码` }}</van-button>
          </template>
        </van-field>
      </p>
      <p>
        <van-button native-type="button" class="title" type="primary" :disabled="submitDisabled" @click="submit">提交</van-button>
      </p>
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getPhoneCode, login, registOrNot, getSignConsignDetail } from "../utils/api";
// import wx from "weixin-js-sdk";
//
export default {
  name: "LoginView",
  data() {
    return {
      userData: {
        username: "",
        mobileCode: "",
        grantType: "sms_code",
      },
      count: 300,
      btnDisabled: false,
      filedDisabled: false, //手机输入框是否禁用
      submitDisabled: false, //提交按钮是否禁用
      detail: {}, //托运单详情
      ticket: "",
    };
  },
  async mounted() {
    // this.getConsignDetail();
  },
  methods: {
    async ceshi() {
      let registerData = {
        openId: "o1Imj680XX-VqeUPmiTwmKoGz6ls",
        mobile: "14704194480",
        // mobile: "13130991051",
      };
      let checkRes = await registOrNot(registerData);
      if (checkRes.data?.data) {
        let data = {
          grantType: "open_credentials",
          ticket: "o1Imj680XX-VqeUPmiTwmKoGz6ls",
        };
        let res = await login(data);
        console.log(res, "denglu");
        if (res.data.retCode == "000000") {
          this.$store.commit("user/save_userInfo", res.data.data.additionalInformation.userModel);
          this.$store.commit("user/save_accesstoken", res.data.data.accessToken);
          this.$store.commit("user/save_refreshtoken", res.data.data.refreshToken);
          this.$router.push({
            path: "/scan",
            query: {
              shipmentId: 6397,
            },
          });
        }
      }
    },
    //发送验证码
    sendSms() {
      let timer = null;
      timer = setInterval(() => {
        this.count--;
        this.btnDisabled = true;
        if (this.count == 0) {
          clearInterval(timer);
          this.btnDisabled = false;
          this.count = 300;
        }
      }, 1000);
      let validate = this.$refs.vanForm.validate("tel");
      validate
        .then(async () => {
          let res = await getPhoneCode(this.userData.username);
          // console.log(res, "res");
          if (res.data.retCode == "000000") {
            Toast("已发送请注意查收");
          }
        })
        .catch((err) => {
          console.log(err, "err");
        });
    },
    //提交验证
    submit() {
      let validate = this.$refs.vanForm.validate();
      validate
        .then(async () => {
          this.submitDisabled = true;
          Toast("正在提交请稍等");
          //验证司机手机号是否注册过
          let mobileRes = await registOrNot(this.userData.username);
          // console.log(mobileRes, "验证司机手机号");
          if (mobileRes.data?.data) {
            Toast("正在登陆中请稍等");
            //登录获取token
            let res = await login(this.userData);
            // console.log(res, "登录");
            if (res.data.retCode == "000000") {
              Toast("登陆成功");
              const { roleId } = res.data.data.additionalInformation.userModel;
              // console.log(roleId, "roleid");
              const driverCheck = roleId == 2 && this.$store.state.scan.signBy == 1;
              const contactCheck = roleId == 3 && this.$store.state.scan.signBy == 2;
              // 验证当前签收人类型
              if (driverCheck || contactCheck) {
                //登录完成后存一下用户登录信息和状态
                this.$store.commit("user/save_userInfo", res.data.data.additionalInformation.userModel);
                this.$store.commit("user/save_accesstoken", res.data.data.accessToken);
                this.$store.commit("user/save_refreshtoken", res.data.data.refreshToken);
                //存一下用户手机号和验证码
                this.$store.commit("user/save_mobile", this.userData.username);
                this.$store.commit("user/save_sms", this.userData.mobileCode);
                this.saveInfo();
                await this.$router.push("/scan");
              } else {
                Toast("签收人类型不同");
              }
              this.submitDisabled = false;
            } else {
              this.submitDisabled = false;
            }
          } else {
            //存一下用户手机号和验证码
            Toast("用户未注册请先注册");
            this.$store.commit("user/save_mobile", this.userData.username);
            this.$store.commit("user/save_sms", this.userData.mobileCode);
            // window.localStorage.setItem("shipmentid", this.detail.id);
            this.$store.commit("scan/save_shipmentid", this.detail.id);
            this.$store.commit("scan/save_deliveryCompany", {
              id: this.detail.driverCompanyId,
              name: this.detail.driverCompany,
            });
            await this.$router.push("/register");
            this.submitDisabled = false;
          }
        })
        .catch((err) => {
          Toast("请将信息填写完整");
          this.submitDisabled = false;
          console.log(err);
        });
    },
    //存各种信息状态
    saveInfo() {
      //存一下托运单id和单号和物流公司
      // this.$store.commit("scan/add_ids", this.detail.id);
      this.$store.commit("scan/add_shipmentNo", {
        id: this.detail.id,
        shipmentNo: this.detail.shipmentNo,
      });
      // window.localStorage.setItem("shipmentid", this.detail.id);
      this.$store.commit("scan/save_shipmentid", this.detail.id);
      this.$store.commit("scan/save_deliveryCompany", {
        id: this.detail.driverCompanyId,
        name: this.detail.driverCompany,
      });
      // 存一下单据类型;
      // window.localStorage.setItem("billType", this.detail.billType);
      this.$store.commit("scan/save_billType", this.detail.billType);
      // 存货运方式
      this.$store.commit("scan/save_sendType", this.detail.sendType);
      // 存扫描状态
      this.$store.commit("scan/save_scanStatus", 1);
    },
    //获取托运单详情
    async getConsignDetail() {
      let res = await getSignConsignDetail(Number(this.$route.query.shipmentId));
      // console.log(res, "托运单详情");
      if (res.data.retCode == "000000") {
        this.detail = res.data.data.shipmentModel;
        if (res.data.data.shipmentModel.complete) {
          //如果托运单状态已经是签收状态
          Toast("该托运单已签收");
          this.submitDisabled = true;
        } else if (!res.data.data?.signModels) {
          this.$store.commit("scan/save_signBy", 1);
          // window.localStorage.setItem("signBy", 1); //存一下签收类型
          //如果是未签收，司机没签收的状态下
          if (res.data.data?.shipmentModel?.driverMobile) {
            this.userData.username = res.data.data?.shipmentModel?.driverMobile;
            this.filedDisabled = true;
          }
        } else if (res.data.data?.signModels.length == 1) {
          // console.log('客户')
          // window.localStorage.setItem("signBy", 2);
          this.$store.commit("scan/save_signBy", 2);
          //如果是司机签收完了，客户没签收的状态下
          this.userData.username = res.data.data?.shipmentModel?.contactMobile;
          this.filedDisabled = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
  .logis {
    background: #409eff;
    color: white;
    height: 44px;
    line-height: 44px;
    font-size: 20px;
    letter-spacing: 5px;
  }
  p {
    width: 100%;
    margin-bottom: 10px;
    .title {
      width: 100%;
      letter-spacing: 5px;
      font-size: 20px;
      // background: #009788;
    }
    .van-field {
      font-size: 15px;
      :deep(.van-field__label) {
        width: 5em;
        // margin-right: 0px;
      }
    }
    img {
      width: 80%;
    }
  }
}
</style>
