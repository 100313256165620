import Vue from "vue";
import VueRouter from "vue-router";
import LoginView from "../views/login.vue";
import ScanView from "../views/scan.vue";
// import store from "../store/index";
Vue.use(VueRouter);
export const routes = [
  {
    path: "/",
    component: LoginView,
  },
  {
    path: "/scan",
    component: ScanView,
  },
  {
    path: "/qrcode",
    component: () => import("../views/qrcode.vue"),
  },
  {
    path: "/signature",
    component: () => import("../views/signature.vue"),
  },
  {
    path: "/consignment",
    component: () => import("../views/consignment.vue"),
  },
  {
    path: "/shipment",
    component: () => import("../views/shipment.vue"),
  },
  {
    path: "/register",
    component: () => import("../views/register.vue"),
  },
  // {
  //   path: "/ceshi",
  //   component: () => import("../views/ceshi.vue"),
  // },
];
const router = new VueRouter({
  routes,
  mode: "history",
});
//路由导航守卫
// router.beforeEach((to, from, next) => {
//   let token = store.state.user.accessToken;
//   // console.log(token, "token");
//   // if (token && to.path == "/") {
//   //   next("/scan");
//   // } else
//   // console.log(to,'toto')
//   if (token || to.path == "/" || to.path == "/register") {
//     next();
//     console.log("next");
//   } else {
//     next({
//       path: "/",
//       query: {
//         // shipmentId: window.localStorage.getItem("shipmentid"),
//         shipmentId: store.state.scan.shipmentid,
//       },
//     });
//     console.log("next11");
//   }
// });
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push;

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace;

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

export default router;
